(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/stryktipset/assets/javascripts/app-container.js') >= 0) return;  svs.modules.push('/modules/stryktipset/assets/javascripts/app-container.js');
"use strict";


const {
  useMemo
} = React;
const {
  Redirect,
  Route,
  Switch
} = ReactRouterDOM;
const {
  useSelector
} = ReactRedux;
const {
  data: coreData,
  detect
} = svs.core;
const {
  ModalHidden
} = svs.components.lbUi.modal;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  TipsenNav,
  RoutePlayFallback,
  RouteResultFallback,
  HandlePath
} = svs.components.tipsen.modularBase;
const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  MyBetsRoute: MyBetsRouteSmygen
} = svs.stryktipset;
const LazyResult = tipsenLazy('/cl/tipsen/route-result', () => svs.components.tipsen.routeResult.RouteResult, {
  fallback: RouteResultFallback
});
const LazySpela = tipsenLazy('/cl/tipsen/route-play', () => svs.components.tipsen.routePlay.RoutePlay, {
  fallback: RoutePlayFallback
});
const LazyCoupon = tipsenLazy('/cl/tipsen/route-coupon', () => svs.components.tipsen.routeCoupon.RouteCoupon);
const LazyRouteCompetition = tipsenLazy('/cl/tipsen/route-competition', () => svs.components.tipsen.routeCompetition.RouteCompetition);
const RedirectToRoot = () => React.createElement(Redirect, {
  to: "/"
});
const {
  MyBetsRoute
} = svs.components.tipsen.base;
const AppContainer = () => {
  const displayName = useSelector(state => selectDisplayName(state));
  const handlePathData = useMemo(() => ({
    productName: displayName,
    cmsTitle: coreData.cmsTitle
  }), [displayName]);
  return React.createElement(React.Fragment, null, React.createElement(HandlePath, {
    data: handlePathData
  }), React.createElement(ModalHidden, null, React.createElement(TipsenNav, null), React.createElement(Switch, null, React.createElement(Route, {
    component: LazyResult,
    path: "/resultat/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?"
  }), React.createElement(Route, {
    component: MyBetsRoute,
    path: "/".concat(TipsenModuleGameUrl.MY_BETS)
  }), React.createElement(Route, {
    component: detect.feature('ft-stryktipset-mybets-smygen') ? MyBetsRouteSmygen : RedirectToRoot,
    path: "/".concat(TipsenModuleGameUrl.MY_BETS2)
  }), React.createElement(Route, {
    component: LazyRouteCompetition,
    path: "/tavlingar/:competitionName"
  }), React.createElement(Route, {
    component: LazyCoupon,
    path: "/".concat(TipsenModuleGameUrl.COUPON)
  }), React.createElement(Route, {
    component: LazySpela
  }))));
};
setGlobal('svs.stryktipset.AppContainer', AppContainer);

 })(window);